import React from "react";
import { arrayOf, bool, number, oneOfType, shape, string } from "prop-types";

import { useStyles } from "./LayoutDefault.style";
import Sponsor from "../../../../Sponsor/Sponsor";
import Header from "../../../Header/Header";
import MainStory from "../../../MainStory/MainStory";
import SecondaryStories from "../../../SecondaryStories/SecondaryStories";
import { useTown } from "../../../../../context/TownContext";
import { Skeleton } from "@material-ui/lab";

const LayoutDefault = ({
  adTargets,
  videoAd,
  localNews,
  sponsorInfo,
  section,
  subsection,
  townForSection,
  loading,
}) => {
  const classes = useStyles();
  const { mainStory, secondaryStories } = localNews || {};
  let { town } = useTown();
  const showHeader = section || subsection ? true : false;

  if (townForSection) {
    town = townForSection;
  }

  const showStoriesFor = {
    section: section ? true : false,
    subsection: subsection ? true : false,
    town: town ? true : false,
    overall: town === undefined && section === undefined && subsection == undefined,
  };

  if (loading) {
    return (
      <Skeleton variant="rect" width="100%" height="40vh" animation="wave" />
    );
  }

  return (
    <>
      {(showHeader || sponsorInfo) && (
        <div className={classes.header_container}>
          {showHeader && <Header town={town} section={section} subsection={subsection}/>}
          {sponsorInfo && (
            <div className={classes.sponsor_container}>
              <Sponsor sponsor_info={sponsorInfo} />
            </div>
          )}
        </div>
      )}

      <section className={classes.news_section}>
        <div className={classes.news_container}>
          <MainStory mainStory={mainStory} showStoriesFor={showStoriesFor} />
          <SecondaryStories
            adTargets={adTargets}
            secondaryStories={secondaryStories}
            showStoriesFor={showStoriesFor}
            videoAd={videoAd}
          />
        </div>
      </section>
    </>
  );
};

LayoutDefault.propTypes = {
  adTargets: oneOfType([
    shape({
      towns: arrayOf(string),
      sections: arrayOf(string),
      sponsor_display: arrayOf(string),
    }),
    arrayOf(
      shape({
        towns: arrayOf(string),
        sections: arrayOf(string),
        sponsor_display: arrayOf(string),
      })
    ),
  ]),
  sponsorInfo: shape({
    page_type: string,
    sponsor_attr: string,
    top_local_news: bool,
    town: string,
    height: number,
    width: number,
    categories_slug: arrayOf(string),
    columns_slug: arrayOf(string),
    sections_slug: arrayOf(string),
  }),
  section: shape({
    id: number.isRequired,
    slug: string.isRequired,
    name: string.isRequired,
  }),
  townForSection: shape({
    id: number.isRequired,
    slug: string.isRequired,
    name: string.isRequired,
  }),
  loading: bool,
};

export default LayoutDefault;
