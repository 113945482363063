import React from "react";
import { arrayOf, bool, number, shape, string } from "prop-types";
import clsx from "clsx";
import { useStyles } from "./MainStory.style";
import VerticalLayout from "../../../common/Card/Layouts/Vertical/VerticalLayout";
import Card from "../../../common/Card/Card";
import Link from "../../../common/Link/Link";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import values from "../../../common/Theme/themes/breakpoints/values";
import Box from "../../../common/Box/Box";

const MainStory = ({ mainStory, showStoriesFor }) => {
  const classes = useStyles();
  const isMobile = screenWidthIsLowerThan(values.md);
  const description = mainStory?.subheading;

  return (
    <div className={classes.main_story_container}>
      {mainStory && mainStory.media_content.length > 0 && (
        <Card
          children={
            <VerticalLayout
              media={mainStory?.media_content[0]}
              information={
                <div
                  className={
                    showStoriesFor?.overall
                      ? classes.overall_card_info_container
                      : classes.card_title_container
                  }
                >
                  {showStoriesFor?.overall && (
                    <Link
                      level="paragraph_1"
                      children={mainStory?.main_town_info?.name}
                      url={mainStory?.main_town_info?.path}
                      className={classes.overall_town_link}
                    />
                  )}
                  <Box className={classes.card_content_info}>
                    <Link
                      level="h4_sub_header_4"
                      className={classes.card_subheader}
                      url={mainStory?.section_for_front_end?.path}
                    >
                      {mainStory?.section_for_front_end?.name}
                    </Link>
                    <Link
                      level="h1_header_1_mobile"
                      bold
                      className={clsx(
                        classes.card_title,
                        showStoriesFor?.overall && classes.overall_card_title
                      )}
                      children={mainStory?.name}
                      url={mainStory?.url_content}
                    />
                    {!isMobile && (
                      <Link
                        level="h1_header_1_mobile"
                        children={description}
                        className={classes.description}
                        url={
                          !mainStory.firstSection
                            ? mainStory.url_content
                            : mainStory?.articleUrl
                        }
                      />
                    )}
                  </Box>
                </div>
              }
              options={{
                credits: { visible: false },
                media: {
                  clickable: true,
                  townContentUrl: mainStory.url_content,
                  opacityOnFocus: true,
                  withFillImageBackground: false,
                },
                videoPlayer: {
                  width: "100%",
                  height: "100%",
                  showPlayable: true,
                },
              }}
              customClasses={{
                media_container: classes.media_container,
                image: classes.image,
                caption_and_credits_container:
                  classes.caption_and_credits_container,
                top_section: classes.vertical_layout,
              }}
            />
          }
          raised={false}
          withShadow={false}
          noRounded={true}
          classes={{ root: classes.main_card }}
        />
      )}
    </div>
  );
};

MainStory.propTypes = {
  mainStory: shape({
    id: number,
    name: string,
    byline: string,
    url_content: string,
    media_content: arrayOf(
      shape({
        contentUrl: string,
        contentThumbUrl: string,
        contentCarouselUrl: string,
        credits: string,
        caption: string,
        alt_text: string,
        isVideo: bool,
      })
    ),
    truncated_description: string,
    published_date_for_front_end: string,
  }),
  showStoriesFor: shape({
    town: bool,
    section: bool,
    subsection: bool,
    overall: bool,
  }),
};

export default MainStory;
