import React, { Fragment } from "react";
import {
  any,
  arrayOf,
  bool,
  number,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { useStyles } from "./SecondaryStories.style";
import Card from "../../../common/Card/Card";
import VerticalLayout from "../../../common/Card/Layouts/Vertical/VerticalLayout";
import Link from "../../../common/Link/Link";
import MobileCard from "../../Town/Shared/MobileCard/MobileCard";
import VideoAd from "../../Ad/VideoAd";
import SectionMobileCard from "../../Section/Shared/MobileCard/MobileCard";
import { VIDEO_PLAYER_OPTIONS } from "../../../utils/constants/homepageConstants";
import { isAdPlaceholderBlock } from "../../../utils/helpers/homepageHelper";
import AdCard from "../../Homepage/AdCard/AdCard";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import values from "../../../common/Theme/themes/breakpoints/values";

const SecondaryStories = ({
  adTargets,
  secondaryStories,
  showStoriesFor,
  videoAd,
}) => {
  const classes = useStyles();
  const isMobile = screenWidthIsLowerThan(values.md);

  const TopStoryAd = () => {
    if (videoAd && Object.keys(videoAd).length > 0) {
      return (
        <Card
          children={<VideoAd videoAd={videoAd} />}
          noRounded={true}
          withShadow={false}
          classes={{ root: classes.video_ad_card }}
        />
      );
    }
  };

  const DesktopCardLayout = ({ story }) => {
    return (
      <VerticalLayout
        media={story.media_content[0]}
        information={
          <div className={classes.information_container}>
            {showStoriesFor?.overall && (
              <Link
                level="t2_text_2"
                children={story?.main_town_info?.name}
                url={story?.main_town_info?.path}
                underlined
                className={classes.overall_town_link}
              />
            )}
            <Link
              level="h4_sub_header_4"
              className={classes.card_subheader}
              url={story?.section_for_front_end?.path}
            >
              {story?.section_for_front_end?.name}
            </Link>
            <Link
              level={showStoriesFor?.overall ? "t3_text_3" : "h5_sub_header_2"}
              bold
              className={classes.name}
              children={story.name}
              url={story?.url_content}
            />
          </div>
        }
        options={{
          caption: { visible: false },
          credits: { visible: false },
          media: {
            clickable: true,
            townContentUrl: story.url_content,
            opacityOnFocus: true,
            withFillImageBackground: false,
          },
          videoPlayer: VIDEO_PLAYER_OPTIONS,
        }}
        customClasses={{
          media_container: classes.media_container,
          image: classes.image,
          caption_and_credits_container: classes.caption_and_credits_container,
          top_section: classes.secondary_story_top_section,
        }}
      />
    );
  };

  const displayAdInPosition = ({
    isMobileScreen,
    position,
    isPlaceholderForAd,
  }) => {
    if (isMobileScreen || isPlaceholderForAd) {
      return position === 0;
    }

    return position === 1;
  };

  const CardForMobile = ({ story }) => {
    if (showStoriesFor?.section) {
      return <SectionMobileCard item={story} />;
    }
    if (showStoriesFor?.subsection) {
      return <SectionMobileCard item={story} />;
    }
    if (showStoriesFor?.town) {
      return <MobileCard item={story} withFillImageBackground={false} />;
    }

    if (showStoriesFor?.overall) {
      return <DesktopCardLayout story={story} />;
    }
  };

  return (
    <div className={classes.secondary_stories_container}>
      <>
        {secondaryStories?.map((story, index) => (
          <Fragment key={index}>
            {!showStoriesFor?.overall &&
              displayAdInPosition({
                isMobileScreen: isMobile,
                position: index,
                isPlaceholderForAd: isAdPlaceholderBlock(),
              }) && (
                <Fragment key={`advertisement-${index}`}>
                  {videoAd ? (
                    <TopStoryAd />
                  ) : (
                    <AdCard
                      adTargets={adTargets}
                      adSize={[300, 250]}
                      adUnit="tap_bullseye_001"
                      options={{
                        forTopStories: true,
                      }}
                    />
                  )}
                </Fragment>
              )}

            {!isAdPlaceholderBlock() && story.media_content && (
              <Fragment key={`story-${index}`}>
                <Card
                  children={
                    isMobile ? (
                      <CardForMobile story={story} />
                    ) : (
                      <DesktopCardLayout story={story} />
                    )
                  }
                  raised={false}
                  withShadow={false}
                  noRounded={true}
                  classes={{ root: classes.secondary_card }}
                />
              </Fragment>
            )}
          </Fragment>
        ))}

        {!showStoriesFor?.overall && secondaryStories?.length === 1 && (
          <>
            {videoAd ? (
              <TopStoryAd />
            ) : (
              <AdCard
                adTargets={adTargets}
                adSize={[300, 250]}
                adUnit="tap_bullseye_001"
                options={{
                  forTopStories: true,
                  showAdText: isMobile,
                }}
              />
            )}
          </>
        )}
      </>
    </div>
  );
};

SecondaryStories.propTypes = {
  adTargets: oneOfType([
    shape({
      towns: arrayOf(string),
      sections: arrayOf(string),
      sponsor_display: arrayOf(string),
    }),
    arrayOf(
      shape({
        towns: arrayOf(string),
        sections: arrayOf(string),
        sponsor_display: arrayOf(string),
      })
    ),
  ]),
  secondaryStories: arrayOf(
    shape({
      id: number,
      name: string,
      byline: string,
      url_content: string,
      media_content: arrayOf(
        shape({
          contentUrl: string,
          contentThumbUrl: string,
          contentCarouselUrl: string,
          credits: string,
          caption: string,
          alt_text: string,
          isVideo: bool,
        })
      ),
      truncated_description: string,
      published_date_for_front_end: string,
    })
  ),
  videoAd: any,
  showStoriesFor: shape({
    town: bool,
    section: bool,
    subsection: bool,
    overall: bool,
  }),
};

export default SecondaryStories;
